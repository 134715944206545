import React, { Component } from "react";
import { NavLink } from "react-router-dom"
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import DetailsAbdruckVeranstaltung from "../subcomponents/DetailsAbdruckVeranstaltung";

class Veranstaltungen extends Component {

  state = { detailsAbdruckVeranstaltung: false, detailsKleineKuenstler: false }

  toggleDetailsAbdruckVeranstaltung = () => {
    if (this.state.detailsAbdruckVeranstaltung === true)
      this.setState({ detailsAbdruckVeranstaltung: false });
    else
      this.setState({ detailsAbdruckVeranstaltung: true });
  }

  toggleDetailsKleineKuenstler = () => {
    if (this.state.detailsKleineKuenstler === true)
      this.setState({ detailsKleineKuenstler: false });
    else
      this.setState({ detailsKleineKuenstler: true });
  }

  mehrStyle = {
    border: 0,
    backgroundColor: "white",
    outline: "none",
    color: "#337AB7"
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-18">
            <h1>Veranstaltungen</h1>
          </div>
        </div>

        <div id="Keramikmalen">
          <div className="gnd-22 bt-1p">
            <h2>Freies Keramikmalen</h2>
          </div>
          <div className="gnd-22 pb-10 pt-10">
            <p>
              Es ist wöchentlich möglich beliebige Keramik im Keramikmalstudio zu bemalen. <br />
              Freie Maltermine findet ihr <NavLink exact to="/termine"><b>hier</b></NavLink>.
            </p>
          </div>
        </div>

        <div id="andereVeranstaltungen" className="row pt-10 bt-1p">
          <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 pb-10 gnd-22">
            <h2>Junggesellinnenabschiede, Firmenfeiern oder andere Veranstaltungen zu Sonderterminen</h2>
            <p>
              Bei diesen Terminen entsteht zusätzlich zu der ausgewählten Keramik eine Veranstaltungsgebühr von 65 Euro. <br /><br />
              Es können 8 bis 10 Personen an der Veranstaltung teilnehmen. 
            </p>
          </div>
        </div>

        <div id="kleineKuenstler" className="row pt-10 bt-1p">
          <br></br>
          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
            <img src="./images/Fuss-und-Handabdruecke/IMG_4700.jpg" className="w-100p" alt="kleine Künstler"
              title="Kind bei der Gestaltung eines Tellers mit Handabr /uck für die Oma." />
          </div>
          <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 pb-10 gnd-22">
            <h2>Kleine Künstler gestalten individuelle Geschenke und Erinnerungen.</h2><br />
            <p>Kreativangebot für Eltern und ihre Kinder zum Bemalen von Keramik nach Absprache. </p>
            <div id="DetailskleineKuenstler" className="row  pt-10 gnd-22 pb-10" style={{ padding: "6% 0 0 0" }}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3;">
                <img src="./images/ostereier/IMG_4323.jpg" className="w-100p" alt="Osterei" title="The Fire Egg" />
              </div>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3;">
                <img src="./images/Fuss-und-Handabdruecke/IMG_4797.jpg" className="w-100p" alt="Abdruck Hände"
                  title="Abdruck Füße eines Kleinkindes im Alter von 6 Monaten. Die Keramik wurde zusätzlich mit Stempeltechnik verziert." />
              </div>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3;">
                <img src="./images/kleine-Kuenstler/IMG_4661-1.jpg" className="w-100p" alt="Schale mit gestempelten Herzen"
                  title="Diese Schale wurde mit unterschiedlichen Herzen gestempelt." />
              </div>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3;">
                <img src="./images/ostereier/IMG_4310.jpg" className="w-100p" alt="Osterei"
                  title="Frei bemaltes Osterei im Strohnest." />
              </div>
            </div>
          </div>
        </div>

        
        <div id="Kindergeburtstag" className="row pt-10 bt-1p">
          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
            <img src="./images/veranstaltungen/Kindergeburtstag_Suesse_Tassen.jpg" className="w-100p" alt="Teller mit Delfin"
              title="Teller mit Delfin" />
          </div>
          <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 pb-10 gnd-22">
            <h2>Kindergeburtstage</h2>
            <p>
              Eine Geburtstagsfeier bei Herzenston ist ein schönes Erlebnis!<br />
              Die Kinder verbringen gemeinsame Zeit mit Freunden, können gleichzeitig kreativ sein und bekommen dann auch noch eine schöne Erinnerung mit nach Hause.<br /><br />
              Im Vorfeld bereite ich das individuelle Thema zusammen mit dem Geburtstagskind vor.<br /><br />
            </p>
            <p className="gnd-22-c2"><NavLink exact to="/kindergeburtstag"><b>mehr . . .</b></NavLink></p>
          </div>
        </div>

        <div id="Abdruck" className="row pt-10 bt-1p">
          <br></br>
          <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 pb-10 gnd-22">
            <h2>Hand- und Fußabdruck für Baby's</h2>
            <p>
              Eine ewige Erinnerung und ein schönes Geschenk:<br />
              Der Fußabdruck deines Babys, deines Kindes oder deines Enkels auf Teller, Tasse oder einer anderen
              Keramik.
            </p>
            <p>Hinweis: Unsere Farben sind ungiftig und wasserlöslich.</p>
            <p>Bitte vereinbare für diese Aktion einen Termin über E-Mail oder telefonisch. <NavLink exact to="/kontakt">Hier
              findest du die Kontaktdaten.</NavLink></p>
            <button className="gnd-22-c2" onClick={this.toggleDetailsAbdruckVeranstaltung} style={this.mehrStyle}><p><b>mehr . .
              .</b></p></button>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
            <img src="./images/Fuss-und-Handabdruecke/IMG_4797.jpg" className="w-100p" alt="Abdruck Hände"
              title="Abdruck Füße eines Kleinkindes im Alter von 6 Monaten. Die Keramik wurde zusätzlich mit Stempeltechnik verziert." />
          </div>
        </div>
        {this.state.detailsAbdruckVeranstaltung === true ? <DetailsAbdruckVeranstaltung></DetailsAbdruckVeranstaltung> : ""}

        <div id="Keramikmalnacht">
          <div className="gnd-22 bt-1p">
            <h2>Keramikmalnacht</h2>
          </div>
          <div>
            {/* <AliceCarousel autoPlay autoPlayInterval="3000" infinite> */}
            <AliceCarousel infinite disableDotsControls>
              <img src="./images/Keramikmalnacht3.jpg" alt="Karin H." className="sliderimg"/>
              <img src="./images/Keramikmalnacht4.jpg" alt="Beim Malen" className="sliderimg"/>
            </AliceCarousel>
          </div>

          <div className="gnd-22 pb-10 pt-10">
            <h3>Was erwartet dich auf einer Keramikmalnacht?</h3>
            <p>Im Mittelpunkt des Abends steht die Freude am „Kreativ Sein“ in Gesellschaft von Gleichgesinnten. <br />
              Passend zur Jahreszeit wähle ich für euch ein Thema und die dazu geeignete Rohkeramik aus. <br />
              Nach einer allgemeinen Einführung in die Keramikmalerei begleite ich eure eigenen Ideen gerne mit meinem „Know How“.
            </p>
          </div>
          
          <div className="gnd-22">
            <h2>Termine:</h2>
            <br></br>
          </div>
        
          <div className="row pt-10 pb-10 gnd-22 bt-1p">
            <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
              <p>Derzeit keine Termine geplant</p>
            </div>
          </div>
          <br /><br />
        </div>

        <br></br>

        <div className="row pt-10 bt-1p">
        <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 pb-10 gnd-22">
            <h2>Vergangene Veranstaltungen bei Herzenston</h2>
            <p>
            Informationen zu vergangenen Veranstaltungen findet ihr <NavLink exact to="/vergangeneVeranstaltungen">hier</NavLink>.<br /><br />
            </p>
          </div>
        </div>

        

      </div>

    );
  }
}
export default Veranstaltungen;